import React from 'react';
import { graphql } from 'gatsby';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style'; //平畑追加
import { LI } from '../components/common/LI.style'; //平畑追加

import DepartmentSchedule from '../components/DepartmentSchedule';
import DoctorInCharge from '../components/DoctorInCharge';
import SymptomExamples from '../components/SymptomExamples';
import MenuCard from '../components/MenuCard';

import DetectedDisease from '../components/DetectedDisease';

import int1lg from '../assets/img/int-1-lg.png';

const DiabetesArticleList = ({ list = [] }) => (
  <Flex column>
    {list.map(({ id, date, title, path }, index) => (
      <Flex
        key={id}
        css={`
          height: 60px;
          margin: 5px 0;
          border-bottom: ${(list.length - 1 !== index && 'solid 1px #ccc') || 'inherit'};
        `}
      >
        <Flex center>
          <span
            css={`
              font-size: 12px;
              color: #666666;
              margin-right: 30px;
            `}
          >
            {date}
          </span>
        </Flex>
        <Flex center>
          <Link to={path}>
            <span
              css={`
                font-size: 16px;
                color: #3b4043;
                overflow-wrap: anywhere;
              `}
            >
              {title}
            </span>
          </Link>
        </Flex>
      </Flex>
    ))}
  </Flex>
);

const Internal = ({ data: { allMarkdownRemark, allFile } }) => {
  const { size } = useTrackedState();
  const diabetesArticles = allMarkdownRemark.edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map(
      ({
        node: {
          id,
          frontmatter: { title, path },
        },
      }) => ({
        id,
        title,
        path,
      })
    );
  return (
    <SubLayout topTitle="糖尿病・内分泌科">
      <Img src={int1lg} />
      <Flex
        justifyAround
        wrap="true"
        css={`
          margin: 20px 0;
        `}
      >
        <FlexItem
          css={`
            max-width: 600px;
          `}
        >
          <H4>診療時間</H4>
          詳細は
          <a href="./schedule">
            <font color="bule">
              <u>こちら</u>
            </font>
          </a>
          のページをご覧ください。
        </FlexItem>
        <FlexItem
          css={`
            max-width: 600px;
          `}
        >
          <H4>担当医</H4>
          神山 ほなみ 医師
        </FlexItem>
      </Flex>
      <Flex
        wrap="true"
        justifyAround
        css={`
          margin-bottom: 30px;
        `}
      >
        <FlexItem
          css={`
            max-width: 600px;
          `}
        >
          <H3>糖尿病は様々な合併症の原因</H3>
          <P>糖尿病で起きる主な合併症は下記の通りです。</P>
          <UL>
            <LI>脳梗塞</LI>
            <LI>狭心症・心筋梗塞</LI>
            <LI>糖尿病性腎症（人工透析）</LI>
            <LI>神経障害（しびれ、感覚障害）</LI>
            <LI>網膜症（目が見えなくなる）</LI>
            <LI>足壊疽</LI>
            <LI>歯周病</LI>
          </UL>
          <P>
            現在の日本の糖尿病有病者、糖尿病予備軍は合わせて推計2000万人と言われており、糖尿病有病者の割合は増え続けています。
            特に、新しく人工透析を始められるか他の半数は、糖尿病性腎症の方です。 こういった
            <b>合併症を防ぐためには、糖尿病の治療をしっかりしていく必要があります。</b>
          </P>

          <H3>当院の糖尿病治療</H3>
          <P>
            当院では、
            <b>
              食事・運動療法などの初期治療から内服治療やインスリン導入、インスリン継続治療まで、幅広く治療をしています。
            </b>
          </P>
          <P>
            糖尿病の食事療法については現在の食事内容の見直しと食事の時間や摂り方も重要です。
            正しい食事や規則正しい食生活を続けることで、体調は改善し、体重は適性に近づき、有酸素運動の併用でインスリンが効きやすくなります。
          </P>
          <P>
            特にご高齢の方は
            <br />
            「運動量の低下」→「筋肉量の低下」→「インスリンが作用しづらくなる」
            <br />
            ことが多く、「レジスタンス運動」という運動が推奨されています。
          </P>
          <P>
            当院は、薬にのみ重点を置くのではなく、こうした食事療法・運動療法を併用することで生活の質を上げ、ストレスを減らし、有意義な時間を送りながら、可能であれば徐々に薬の量を減らしていくことを目指します。
          </P>

          <H3>糖尿病の検査</H3>
          <P>
            当院では糖尿病の指標である
            <b>HbA1c、血糖の結果が5分程度で出ますので、即日結果を説明させて頂きます。</b>
          </P>
          <P>
            なお、体調の変化や感染等による血糖増悪（シックデイ）が考えられる状態を除いて、２か月に一度の外来受診をお勧めいたします。
          </P>

          <H3>定期検査</H3>
          <P>
            糖尿病はさまざまな疾患を併発しやすいため、下記の検査も定期的にお受けいただくことをお勧めしています。
          </P>

          <H4>動脈硬化のチェック</H4>
          <UL>
            <LI>頚動脈エコー</LI>
            <LI>心臓超音波</LI>
          </UL>

          <H4>がん関連</H4>
          <UL>
            <LI>腹部エコー</LI>
            <LI>胃カメラ</LI>
            <LI>大腸カメラ</LI>
          </UL>

          <P>
            <b>これらの検査がすべて院内でできることも、当院の特徴</b>となっています。
          </P>
          <P>「こんなことを聞いてもいいのかな」と思うことでも、どうぞお気軽にご相談ください。</P>
          <P>
            糖尿病は中断せずに治療を継続することがとても重要です。ご多忙の際は、
            <a href="https://telemed-app.hirahata-clinic.or.jp/">
              ヒラハタクリニックのオンライン診療
            </a>
            もご利用ください。
          </P>
          <P></P>
          <H3>糖尿病が改善！ レジスタンス運動</H3>
          <p>
            神山ほなみ医師監修の、糖尿病の方向けの運動を紹介した動画です。
            <br />
            ご高齢の方も含め、どなたでも取り組める内容になっています。
          </p>
          <div
            css={`
              margin: 50px auto 20px;
              text-align: center;
            `}
          >
            <iframe
              src="https://www.youtube.com/embed/oDK3vSWM2eU"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <p
            css={`
              margin: 0 auto 50px;
            `}
          >
            大きいサイズでご覧になりたい場合は
            <a
              href="https://www.youtube.com/watch?v=oDK3vSWM2eU&feature=youtu.be"
              target="_blank"
              css={
                'text-decoration:none;background:linear-gradient(transparent 60%, #F5D100 0%);color:#000;font-weight:500;'
              }
            >
              こちら(YouTubeに移動します)
            </a>
          </p>

          {/* 
          <H3>糖尿病・内分泌科のその他の記事</H3>
         <DiabetesArticleList list={diabetesArticles} />
         */}
        </FlexItem>
      </Flex>

      <H2Background>
        <H2 size={size}>一般内科</H2>
      </H2Background>
      <Flex
        wrap="true"
        justifyAround
        css={`
          margin-bottom: 30px;
        `}
      >
        <FlexItem
          css={`
            max-width: 600px;
          `}
        >
          <P>
            当院は、渋谷で働く方々の健康を守る「砦」になりたいと考えています。
            緊急性の高い疾患にも対応できるよう、迅速検査機器を多く設置しており、また、超音波検査装置については大学病院で多く利用されているものを導入し、女性の超音波専門技師による腹部エコー、頚動脈エコー、甲状腺エコー、心エコー、乳腺エコーを提供しております。
            さらに、糖尿病・内分泌科外来、呼吸器内科外来、循環器（心臓・血管）外来、甲状腺外来もご用意し、広く多くの疾患に対応できる体制を構築しています。
            「どの科に行っていいか分からない」というときも、ぜひ当院でご相談ください。
          </P>
        </FlexItem>
        {/* <DetectedDisease content="急性心筋梗塞、膠原病、甲状腺がん、乳がん、胃がん、大腸がん、悪性黒色腫、皮膚がん、その他多数。" /> */}
      </Flex>
      <H3 size={size}>診断書について</H3>
      <Flex
        column
        center
        css={`
          margin-bottom: 40px;
        `}
      >
        <P>各種診断書発行希望の方は医師にご相談ください。</P>
      </Flex>
    </SubLayout>
  );
};

export default Internal;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/diabetes-articles/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
